import React, { useState } from "react";
import { Accordion, Card, Container } from "react-bootstrap";
import Divider from "../components/common/divider";
import FullWidthCta from "../components/common/FullWidthCta";
import WayOfWork from "../components/common/WayOfWork";
import Layout from "../components/site-wide/layout/layout";
import constants from "../utils/constants";
import classnames from 'classnames';

const AvtoPage = () => {

    const [activeKey, setActiveKey] = useState("0");

    return (
        <Layout
            title={constants.AVTO.META.TITLE}
        >
            <Container className="my-5 py-5">
                <div className="text-center mb-5">
                    <h1 className="mt-5">Автоключарски услуги</h1>
                    <Divider addClassName="mb-5" />
                    <p>Автоключарски услуги, екип от професионално обучени специалисти</p>
                </div>


                <Accordion activeKey={activeKey}>
                    <Card>
                        <Accordion.Toggle eventKey='0' onClick={() => setActiveKey('0')} as={Card.Header} className={classnames({ "active": '0' === activeKey })}>
                            Аварийно отключване от автоключар
                        </Accordion.Toggle>

                        <Accordion.Collapse eventKey='0'>
                            <Card.Body>
                                Ако сте забравили, счупили, загубили или са откраднали Вашия ключ на автомобила, микробуса или камиона- не се колебайте да ни потърсите! Екипът ни ще се отзове веднага на посочен от Вас адрес и бързо ще отключим Вашия автомобил без поражения!
                        </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                    <Card>
                        <Accordion.Toggle eventKey='1' onClick={() => setActiveKey('1')} as={Card.Header} className={classnames({ "active": '1' === activeKey })}>
                            Ремонт на автомобилни ключалки
                        </Accordion.Toggle>

                        <Accordion.Collapse eventKey='1'>
                            <Card.Body>
                                <ul className="key-list">
                                    <li>
                                        Монтаж,демонтаж на авто ключалки и брави
                                </li>
                                    <li>
                                        Смяна на шифър
                                </li>
                                    <li>
                                        Отваряне на блокирали врати и багажници
                                </li>
                                </ul>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>


                    <Card>
                        <Accordion.Toggle eventKey='2' onClick={() => setActiveKey('2')} as={Card.Header} className={classnames({ "active": '2' === activeKey })}>
                            Изработка на автомобилни ключове
                        </Accordion.Toggle>

                        <Accordion.Collapse eventKey='2'>
                            <Card.Body>
                                Нашият ключарски център разполага с електронни машини, които прецизно и бързо могат да изработят автоключове за всички марки и модели автомобили, бусове и камиони.
                        </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                    <Card>
                        <Accordion.Toggle eventKey='3' onClick={() => setActiveKey('3')} as={Card.Header} className={classnames({ "active": '3' === activeKey })}>
                            Транспондери и дистанционни
                    </Accordion.Toggle>

                        <Accordion.Collapse eventKey='3'>
                            <Card.Body>
                                Копиране, генериране и вписване на имобилайзери и дистанционни на най-различни марки и модели автомобили
                        </Card.Body>
                        </Accordion.Collapse>
                    </Card >

                    <Card>
                        <Accordion.Toggle eventKey='4' onClick={() => setActiveKey('4')} as={Card.Header} className={classnames({ "active": '4' === activeKey })}>
                            Смяна на кутийки
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey='4'>
                            <Card.Body>
                                Смяна на кутийки, батерии и бутони на авто ключове и дистанционни
                        </Card.Body>
                        </Accordion.Collapse>
                    </Card >

                </Accordion >
            </Container >
            <FullWidthCta />

            <WayOfWork />

        </Layout >
    )
}

export default AvtoPage;
